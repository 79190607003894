import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formContainer", "successContainer", "subheading", "authenticationBlock", "authenticationModal", "login", "signup", "closeAuthenticationButton"]

  onSubscribeSuccess(event) {
    let [data, status, xhr] = event.detail;
    // Hide the form
    if (data.success) {
      this.formContainerTarget.classList.add('d-none')
      this.successContainerTarget.classList.add('visible');
    } else {
      this.subheadingTarget.innerText = 'An error occurred: ' + data.errors[0];
    }
  }

  onSubscribeError(event) {
    let [data, status, xhr] = event.detail;
    this.subheadingTarget.innerText  = 'An error occurred subscribing';
  }

  createOverlay( placement ) {
    let overlay = document.createElement('div');
    overlay.className = 'overlay';
    placement.appendChild(overlay);
    document.body.classList.add('noscroll');
  }

  removeOverlay( ) {
    let overlay = document.getElementsByClassName('overlay')[0];
    overlay.remove();
    document.body.classList.remove('noscroll');
  }

  openLogin() {
    if ( !this.loginTarget.classList.contains('visible')) {
      this.loginTarget.classList.add('visible')
      this.signupTarget.classList.remove('visible')
    }
  }

  openSignup() {
    if ( !this.signupTarget.classList.contains('visible')) {
      this.signupTarget.classList.add('visible')
      this.loginTarget.classList.remove('visible')
    }
  }

  openModal(event) {
    this.authenticationModalTarget.classList.add('visible')
    this.createOverlay( this.authenticationBlockTarget );
    this.closeAuthenticationButtonTarget.classList.add('visible');

    if ( event.currentTarget.getAttribute('data-goal') == 'login' ) {
      this.openLogin()
    } else {
      this.openSignup()
    }
  }

  closeAuthentication() {
    this.authenticationModalTarget.classList.remove('visible')
    this.signupTarget.classList.remove('visible')
    this.loginTarget.classList.remove('visible')
    this.removeOverlay();
  }
}