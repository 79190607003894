import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  loadUrlInCurrentTab() {
    setTimeout(() => {
      window.location.assign(this.urlValue);
    }, 100);
  }
}