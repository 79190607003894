import Rails from 'rails-ujs'

export function secondsToTime(value) {
  let date = new Date(null);
  date.setSeconds(value);
  return date.toISOString().substr(11, 8);
}

export function post(url, params) {
  let opt = {
    method: 'POST',
    body: new URLSearchParams(params),
    headers: {
      'X-CSRF-Token': Rails.csrfToken()
    }
  }

  return fetch(url, opt).then((resp) => resp.json())
}

export class GoogleConversionTracking {
  constructor(reportValues, sendTo) {
    this.scriptUrl = '//www.googletagmanager.com/gtag/js?id=AW-814094295'
    this.scriptId = 'google-conversion-tracking-script'
    this.initialized = false;
    this.reportValues = reportValues
    this.sendTo = sendTo
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      // If the script was loaded already, don't do anything
      if (!document.querySelector(`script#${this.scriptId}`)) {
        const head = document.getElementsByTagName("head")[0]
        const script = document.createElement("script")

        script.id = this.scriptId
        script.type = "text/javascript"
        script.charset = "utf-8"
        script.async = true
        script.timeout = 120000
        script.src = this.scriptUrl
        // If something unexpected happened when loading the script, reject the promise
        script.onerror = () => reject()
        // If the script was loaded successfully, resolve the promise
        script.onload = () => resolve()

        head.appendChild(script)
      }
      else {
        // If the script was inserted already, we're good.
        resolve()
      }
    })
  }

  reportConversion(value) {
    // Make sure we have the <script> loaded, otherwise we can't
    // report any conversions! If it was loaded already, this
    // won't have any impact on performance.
    this.loadScript().then(() => {
      this.gtag_report_conversion(value)
    })
  }

  // Code adapted from the official Google Ad's code
  gtag_report_conversion(value) {
    if (!this.initialized) {
      this.gtag('js', new Date());
      this.gtag('config', 'AW-814094295');
      this.initialized = true;
    }

    let callback = function () {};

    let object = {
      'send_to': this.sendTo,
      'event_callback': callback
    }

    if (this.reportValues) {
      object['value'] = value || 1.0
      object['currency'] = 'USD'
    }

    this.gtag('event', 'conversion', object);

    return false;
  }

  gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }
}
