import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['block', 'button']
  static values = { moreText: String, lessText: String }

  toggleVisibility(event) {
    const status = event.currentTarget.dataset.visibility;
    const hideClassAction = status === 'hidden' ? 'remove' : 'add';
    const newTextValue = status === 'hidden' ? this.lessTextValue : this.moreTextValue;

    this.blockTargets.forEach(block => block.classList[hideClassAction]('hidden'));
    this.buttonTarget.innerText = newTextValue;
    event.currentTarget.dataset.visibility = status === 'hidden' ? 'shown' : 'hidden';
  }

}