import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container', 'parent', 'child']

  showChild(e) {
    const radios = this.radioButtons

    if (radios.length > 0) {
      const children = this.containerTarget.querySelectorAll('[data-child]')
      const currentParent = e.currentTarget.closest('[data-parent]')
      children.forEach( item => {
        if (item.classList.contains('visible')) {
          item.classList.remove('visible')
          item.querySelectorAll('input').forEach(input => (input.disabled = true))
        }
      })

      if (currentParent !== null) {
        const child = currentParent.querySelector('[data-child]')
        child.classList.add('visible')
        child.querySelectorAll('input').forEach(input => {
          if (!input.hasAttribute('data-hidden')) (input.disabled = false)
        })
      }

    } else {
      this.childTarget.classList.toggle('visible')
      this.childTarget.querySelectorAll('input').forEach(input => (input.disabled = !input.disabled))
    }

  }

  get radioButtons() {
    return this.containerTarget.querySelectorAll('[type="radio"]')
  }

}