import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['contentToCopy']

  copyToClipboard() {
    if (this.hasContentToCopyTarget) {
      const textarea = document.createElement("textarea");
      textarea.value = this.contentToCopyTarget.textContent;
      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);;
    }
  }
}