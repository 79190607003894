import { Controller } from "stimulus"

const categories = []

export default class extends Controller {
  static targets = ['categoryName', 'categoryDays', 'categoriesContainer', 'inputsCategory', 'addCategoryButton']

  saveCategory() {
    const name = this.categoryNameTarget
    const days = this.categoryDaysTarget

    if (name.value.trim() === '' || days.value.trim() === '') {
      if (name.value.trim() === '') (name.closest('.generator-field__group').querySelector('.generator-field__label').classList.add('show-required'))
      if (days.value.trim() === '') (days.closest('.generator-field__group').querySelector('.generator-field__label').classList.add('show-required'))
      return
    }

    const newCategory = {
      name: name.value,
      days: parseInt(days.value),
    };

    categories.push(newCategory);

    name.value = ''
    days.value = ''

    this.inputsCategoryTarget.classList.add('hide')
    this.addCategoryButtonTarget.disabled = false
    this.categoriesInputs.forEach( input => {
      input.disabled = true
      input.setAttribute('data-hidden', '')
    })

    this.showCategory()
  }

  showCategory() {
    this.categoriesContainerTarget.innerHTML = ''

    categories.forEach( (category, index) => {
      const container = document.createElement('div')
      const text = document.createElement('span')
      const removeBtn = document.createElement('button')
      const keys = Object.keys(category)

      container.className = 'saved-category';

      text.textContent = `${category.name} — ${category.days} days`;

      keys.forEach(prop => {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = `cat_${prop}_${index}`;
        input.value = category[prop];
        container.appendChild(input);
      })

      removeBtn.addEventListener('click', () => this.removeCategory(index));

      container.appendChild(text);
      container.appendChild(removeBtn);

      this.categoriesContainerTarget.appendChild(container);
    })
  }

  removeCategory(index) {
    if (index >= 0 && index < categories.length) {
      categories.splice(index, 1)
      this.showCategory()
      if (categories.length === 0) (this.showCategoryInputs())
    }
  }

  showCategoryInputs() {
    if (this.inputsCategoryTarget.classList.contains('hide')) (this.inputsCategoryTarget.classList.remove('hide'))
    this.categoriesInputs.forEach( input => {
      input.removeAttribute('data-hidden')
      input.disabled = false
    })
  }

  get categoriesInputs() {
    return this.inputsCategoryTarget.querySelectorAll('input')
  }
}