import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['selectedValue', 'inputValue', 'list', 'button', 'hiddenInput']

  connect() {
    this.lastToggleEvent = null
    window.addEventListener('click', this.closeOnClickOutside.bind(this))
  }

  disconnect() {
    window.removeEventListener('click', this.closeOnClickOutside.bind(this))
  }

  toggleSelect(event) {
    let visible = this.toggleVisibility()
    this.lastToggleEvent = event;
    event.currentTarget.classList.toggle('pressed')
  }

  selectOption(event) {
    this.selectedValueTarget.value = event.currentTarget.innerText;

    if (event.currentTarget.hasAttribute('data-input-hidden') && this.hasHiddenInputTarget) {
      this.hiddenInputTarget.value = event.currentTarget.getAttribute('data-input-hidden')
    }
  }

  toggleVisibility() {
    this.listTarget.classList.toggle('visible')
  }

  closeOnClickOutside(event) {
    // If the dropdown was just opened (in this same click event)
    if (event === this.lastToggleEvent) {
      return;
    }

    // If the dropdown isn't visible
    if (!this.listTarget.classList.contains('visible')) {
      return;
    }

    // If the click occurred inside the dropdown
    if (this.listTarget.contains(event.target)) {
      return;
    }


    this.toggleVisibility()
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.toggle('pressed')
    }
  }
}