import { Controller } from "stimulus"

export default class extends Controller {
  static values = { language: String }

  connect() {
    this.translated = false
    this.observer = new MutationObserver(this.selectLanguage.bind(this))

    this.startTranslation()
  }

  disconnect() {
    this.observer.disconnect()
  }

  selectLanguage() {
    if (this.translated) {
      return
    }

    let options = this.element.querySelectorAll("select option");

    // If the option items aren't loaded yet, try again later.
    if (options.length === 0) {
      return;
    }

    options.forEach(item => {
      if (item.value === this.languageValue) {
        item.selected = true
        item.parentNode.dispatchEvent(new Event('change'))
        this.translated = true
        return
      }
    })
  }

  startTranslation() {
    let interval = setInterval(function() {
      let initialized = false
      try {
        new window.google.translate.TranslateElement({
          pageLanguage: 'en',
          includedLanguages: ['en', this.languageValue].join(","),
          autoDisplay: false,
          layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL
        }, 'translate_dropdown');

        initialized = true
      } catch {}

      // If the Google code was loaded correctly, stop this interval and try to translate
      // the page, either now if the dropdown is ready, or later if it hasn't been loaded
      // yet.
      if (initialized) {
        clearInterval(interval);

        this.observer.observe(this.element, { attributes: true, childList: true, subtree: true })
        this.selectLanguage()
      }

    }.bind(this), 500);
  }
}