import { Controller } from "stimulus"

const mediaQuery = window.matchMedia('(min-width: 768px)')

export default class extends Controller {
  static targets = ['galleryInfo', 'mediaElementContainer', 'toShowForm', 
                    'contentInput', 'commentForm']

  connect() {
    this.resizeObserver = new ResizeObserver(this.adjustMaxHeight.bind(this))
    this.resizeObserver.observe(this.mediaElementContainerTarget)
  }

  disconnect() {
    this.resizeObserver.unobserve(this.mediaElementContainerTarget)
  }

  adjustMaxHeight() {
    this._adjustMaxHeight()
  }

  _adjustMaxHeight(extraHeight = 0, onlyIfEqual = false) {
    if (!mediaQuery.matches) {
      // Let's do this, in case the window is resized (from desktop to a smaller resolution)
      this.galleryInfoTarget.style.maxHeight = null
      return;
    }

    // If both heights are equal (and we want that)... or if the picture's height is lower
    // than the info block's height, adjust its `max-height`
    if (onlyIfEqual && this.infoAndMediaHaveSameHeight || this.galleryInfoTarget.clientHeight < this.mediaElementContainerTarget.clientHeight) {
      let value = Math.max(this.mediaElementContainerTarget.clientHeight + extraHeight, 500)
      this.galleryInfoTarget.style.maxHeight = `${value}px`
    }
  }

  get infoAndMediaHaveSameHeight() {
    return this.galleryInfoTarget.clientHeight === this.mediaElementContainerTarget.clientHeight
  }

  onCommentSuccess() {
    this.hideForm()
    this.toShowFormTarget.remove()
  }

  showForm(event) {
    this.contentInputTarget.focus()
    event.currentTarget.classList.add('visible')
    this.commentFormTarget.classList.add('visible')

    this._adjustMaxHeight(240, true)
  }

  hideForm() {
    this.toShowFormTarget.classList.remove('visible')
    this.commentFormTarget.classList.remove('visible')

    setTimeout(() => {
      this._adjustMaxHeight(-240, true)
    }, 200)
  }

}