import { Controller } from "stimulus"

export default class extends Controller {
  static values = { test: String, pubId: String, styleId: String, keywords: String, containerName: String, count: Number }

  connect() {
    this.insertAndFetch()
  }

  insertAndFetch() {
    return new Promise((resolve, reject) => {
      this.insertScriptUrl().then(() => {
        console.log('Running...')
        ;(function(g, o) {
          g[o] =
            g[o] ||
            function() {
              ;(g[o]["q"] = g[o]["q"] || []).push(arguments)
            }
          g[o]["t"] = 1 * new Date()
        })(window, "_googCsa")

        // Parameters to be sent
        let pageOptions = {
          pubId: this.pubIdValue,
          styleId: this.styleIdValue,
          adtest: this.testValue,
          query: this.keywordsValue,
          adsafe: 'high'
        }

        let adblock1 = {
          container: this.containerNameValue,
          number: this.countValue
        }

        _googCsa("ads", pageOptions, adblock1)

        resolve()
      })
    })
  }

  insertScriptUrl() {
    return new Promise((resolve, reject) => {
      const scriptId = 'adsense-search-script'

      // If the <script> exists already
      if (document.querySelector(scriptId)) {
        return;
      }

      const head = document.getElementsByTagName("head")[0]
      const script = document.createElement("script")

      script.id = scriptId
      script.type = "text/javascript"
      script.async = true
      script.timeout = 120000
      script.src = "https://www.google.com/adsense/search/ads.js"
      script.onload = () => resolve()

      head.appendChild(script)
    })
  }
}