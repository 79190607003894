import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['filtersBar', 'popup']

  toogleFilters() {
    this.filtersBarTarget.classList.toggle('visible');
  }

  toggleSelect(event) {
    let currentDropdown = event.currentTarget.querySelector('.dropdown');
    currentDropdown.classList.toggle('visible');
  }

  selectOption(event) {
    let currentSelection = event.currentTarget.innerText;
    let currentDropdown = event.currentTarget.closest('.dropdown__input').querySelector('.dropdown__selection');

    currentDropdown.value = currentSelection;
  }

  createOverlay( placement ) {
    let overlay = document.createElement('div');
    overlay.className = 'overlay';
    placement.appendChild(overlay);
    document.body.classList.add('noscroll');
  }

  removeOverlay( ) {
    let overlay = document.getElementsByClassName('overlay')[0];
    overlay.remove();
    document.body.classList.remove('noscroll');
  }

  openPopup(event) {
    if ( !this.popupTarget.classList.contains('visible') ) {
      this.popupTarget.classList.add('visible');
      document.body.classList.add('noscroll');

      let parent = event.currentTarget.parentElement;
      this.createOverlay( parent );
    }
  }

  closePopup() {
    this.popupTarget.classList.remove('visible');
    document.body.classList.remove('noscroll');

    this.removeOverlay();
  }

  toggleDropdown(event) {
    let currentDropdown = event.currentTarget.nextElementSibling
    console.log(currentDropdown)
    currentDropdown.classList.toggle('visible');
  }
}