import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['bar', 'reference']

  showBar() {
    this.referenceDistance = this.referenceTarget.getBoundingClientRect().top
    if (this.referenceDistance <= 0 ) {
      this.barTarget.classList.add('visible');
    } else {
      this.barTarget.classList.remove('visible');
    }
  }

}