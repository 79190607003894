import { Controller } from "stimulus"
import { post, GoogleConversionTracking } from "../helpers"

export default class extends Controller {
  static targets = [""]

  track(event) {
    let element = event.currentTarget

    let opt = {
      // The URL where the user will be redirected (encrypted)
      clicked_url: element.dataset.trackUrl,
      url: window.location.href,
      title: document.title,
      placement: element.dataset.placement || '',
      extra: element.dataset.extra || ''
    }

    post('/track-event.json', opt)

    if (element.dataset.trackG) {
      let tracker = new GoogleConversionTracking(true, 'AW-814094295/onBwCLe1o8kCENevmIQD')
      tracker.reportConversion(element.dataset.trackGValue)
    }
  }
}