import { Controller } from "stimulus"

const mediaQuery = window.matchMedia('(max-width: 767px)')

export default class extends Controller {
  static targets = ['content', 'button']
  static values = { moreText: String, lessText: String, maxHeightBlockMobile: Number, maxHeightBlock: Number }

  connect() {
    this.elementToShowStyle = window.getComputedStyle(this.contentTarget)
    this.heightCss = this.elementToShowStyle.getPropertyValue('height')
  }

  get collapseAll() {
    return this.element.dataset.collapseAll === '1'
  }

  toggleCollapse(event) {
    let toCollapse = event.currentTarget,
        height = this.contentTarget.scrollHeight,
        visibleItem = this.element.querySelector('.collapsible.visible'),
        collapseText = toCollapse.querySelector('.collapse-text');

    // If we have to collapse it
    if (toCollapse.classList.contains('visible')) {
      if ( visibleItem.hasAttribute("data-hidden") ) {
        this.contentTarget.style.height = '0'
      } else {
        this.contentTarget.style.height = this.heightCss
      }

      toCollapse.classList.remove('visible')

      if (collapseText) {
        collapseText.innerText = 'See More'
      }

      if (this.hasButtonTarget) {
        this.buttonTarget.innerText = this.moreTextValue
      }

    } else {
      let visibleItems = []
      if (this.collapseAll) {
        visibleItems = [...document.querySelectorAll('.collapsible.visible')]
      }
      else {
        visibleItems = [visibleItem]
      }

      visibleItems.forEach((item) => {
        let target = item.querySelector('.to-show')
        if (item.hasAttribute('data-hidden')) {
          target.style.height = '0'
        } else {
          target.style.height = this.heightCss
        }
        item.classList.remove('visible')
      })

      toCollapse.classList.add('visible')

      if ( collapseText ) {
        collapseText.innerText = 'Hide'
      }

      if (this.hasButtonTarget) {
        this.buttonTarget.innerText = this.lessTextValue
      }

      if ( document.body.classList.contains('lander-cbd_v1') && mediaQuery.matches ) {
        this.contentTarget.style.height = height/2 + 'px'
      } else {
        this.contentTarget.style.height = height + 'px'
      }
    }

  }

  maxHeightToggle(event) {
    const element = this.contentTarget
    const initialHeight = mediaQuery.matches ? this.maxHeightBlockMobileValue : this.maxHeightBlockValue
    const maximumHeight = mediaQuery.matches ? '810px' : '480px'

    if (element.classList.contains('visible')) {
      element.style.maxHeight = initialHeight + 'px'
      element.classList.remove('visible')
      this.buttonTarget.innerText = this.moreTextValue
      event.currentTarget.classList.remove('pressed')
    } else {
      element.style.maxHeight = maximumHeight
      element.classList.add('visible')
      this.buttonTarget.innerText = this.lessTextValue
      event.currentTarget.classList.add('pressed')
    }
  }

}