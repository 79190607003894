import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['videoParent', 'video'];
  static values = { parentClass: String };

  connect() {
    if (this.hasVideoParentTarget && this.hasParentClassValue) {
      const videoParentElement = this.videoParentTarget;
      const videoParentValue = this.parentClassValue;

      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === 'class') {
            if (videoParentElement.classList.contains(videoParentValue)) {
              this.playFromStart();
            } else {
              this.pause();
            }
          }
        });
      });

      observer.observe(videoParentElement, { attributes: true });

      this.disconnectObserver = () => observer.disconnect();
    }
  }

  playFromStart() {
    this.videoTarget.currentTime = 0;
    this.videoTarget.play();
  }

  pause() {
    this.videoTarget.pause();
  }

  disconnect() {
    if (this.disconnectObserver) {
      this.disconnectObserver();
    }
  }
}
