import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['name', 'email', 'container', 'heading', 'subheading']

  // Action used after a Rails remote form is submitted successfully
  onSubscribeSuccess(event) {
    let [data, status, xhr] = event.detail;
    // Hide the form
    if (data.success) {
      this.containerTarget.classList.add('d-none')
      this.headingTarget.innerText = 'Thanks for subscribing!'
    } else {
      this.subheadingTarget.innerText = 'An error occurred: ' + data.errors[0];
    }
  }

  // Action used after a Rails remote form is submitted with an error
  onSubscribeError(event) {
    let [data, status, xhr] = event.detail;
    this.subheadingTarget.innerText  = 'An error occurred subscribing';
  }
}