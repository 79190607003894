import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formContainer", "successContainer", "errorText", "heading"]

  onSuccess(event) {
    let [data, status, xhr] = event.detail;
    // Hide the form
    if (data.success) {
      this.hideElement(this.formContainerTarget)
      this.showElement(this.successContainerTarget)
      if (this.hasErrorTextTarget) {
        this.hideElement(this.errorTextTarget)
        this.errorTextTarget.innerText = ""
      }
      return
    }

    if (this.hasErrorTextTarget) {
      this.showElement(this.errorTextTarget)
      this.errorTextTarget.innerText = 'An error occurred: ' + data.message;
    }
  }

  showElement(element) {
    element.classList.remove('d-none')
  }

  hideElement(element) {
    element.classList.add('d-none')
  }

  onError(event) {
    let [data, status, xhr] = event.detail;
    this.headingTarget.innerText  = 'An error occurred subscribing';
  }
}