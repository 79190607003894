import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String, offset: Number, fetchOnConnect: Boolean }

  connect() {
    this.page = 1
    this.canFetch = true
    if (this.fetchOnConnectValue) {
      this.loadMore();
    }
  }

  scroll() {
    let body = document.body,
        html = document.documentElement

    let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    if (window.pageYOffset >= height - window.innerHeight - this.offsetValue) {
      this.loadMore()
    }
  }

  loadMore() {
    if (!this.canFetch) {
      return
    }

    this.page += 1

    this.canFetch = false

    let url = new URL(this.urlValue)
    url.searchParams.set('page', this.page)

    let headers = { "X-Requested-With": "XMLHttpRequest" }

    fetch(url.toString(), { headers: headers })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.html) {
          // By default, let's try to call `appendResult` on other controllers
          // that might be specified in the HTML. Those might know a custom way of
          // inserting the results we fetched.
          // If there aren't any, we'll do the usual.
          if (!this.appendResultFromCustomController(data.html)) {
            this.element.insertAdjacentHTML('beforeend', data.html)
          }
        }
        this.canFetch = !data.last
      })
      .catch((error) => {
        console.log(error)
      })
  }

  appendResultFromCustomController(data) {
    let found = false
    this.application.controllers.filter(controller =>
      controller.element === this.element
    ).forEach(controller => {
      if (typeof controller.appendResult === 'function') {
        controller.appendResult(data)
        found = true
      }
    })

    return found
  }
}