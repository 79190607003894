import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'modalContainer', 'modal', 'injectedTitle', 'injectedText' ]
  static values = { zIndexTarget: String, showOnce: Boolean }

  connect() {
    this.visible = false
    // document.addEventListener('scroll', this.scrollingOpen )
    if (document.body.classList.contains('scrolling-modal')) {
      this.openModal();
    }
  }

  closeOnClickOutside({ target }) {
    if (!this.modalContainerTarget.querySelector('.overlay')) {
      return
    }

    if (!this.visible) {
      return
    }

    if (this.element.contains(target) && !target.classList.contains('overlay')) {
      return;
    }

    this.closeModal();

    // If this same element has a different controller with a "close()" method, let's
    // call it
    this.closeOtherControllers()
  }

  closeOtherControllers() {
    this.application.controllers.filter(controller =>
      controller.element === this.element
    ).forEach(controller => {
      if (typeof controller.close === 'function') {
        controller.close()
      }
    })
  }

  openModal(event) {
    // If we have to show this modal once per session, and it was shown already
    // TODO: Should we use cookies instead, and set some expiration?
    if (this.showOnce && sessionStorage.getItem(this.sessionKey)) {
      return
    }

    if (this.modalTarget.classList.contains('visible')) return;

    this.addOverlay()

    document.body.classList.add('noscroll');
    document.body.classList.add('onmodal');

    // If we need inject data in modal elements
    if ( !document.body.classList.contains('scrolling-modal') && typeof(event.currentTarget) == 'function' && event.currentTarget.hasAttribute('data-injectable')) {
      let title = event.currentTarget.querySelector('[data-title]').innerText,
          text = event.currentTarget.querySelector('[data-text]').innerText,
          btn = event.currentTarget.querySelector('a.btn').cloneNode(true);

      this.injectedTitleTarget.innerText =  title
      this.injectedTextTarget.innerText = text
      this.modalTarget.appendChild(btn)
    }

    this.modalTarget.classList.add('visible')

    // If we have to revert the z-index of some specific element
    if (this.hasZIndexTargetValue) {
      document.querySelector(this.zIndexTargetValue).classList.add('z-index-50')
    }

    if (this.showOnce) {
      sessionStorage.setItem(this.sessionKey, '1')
    }

    setTimeout(this.setVisible.bind(this), 500)
  }

  setVisible() {
    this.visible = true
  }

  addOverlay() {
    let overlay = document.createElement('div');
    overlay.className = 'overlay';
    this.modalContainerTarget.appendChild(overlay);
  }

  removeOverlay() {
    let overlay = this.modalContainerTarget.querySelector('.overlay')
    overlay.remove();
  }

  closeModal() {
    this.modalTarget.classList.remove('visible')

    this.removeOverlay()

    if ( this.modalTarget.querySelector('a.btn') ) {
      this.modalTarget.querySelector('a.btn').remove()
    }

    // If we have to revert the z-index of some specific element
    if (this.hasZIndexTargetValue) {
      document.querySelector(this.zIndexTargetValue).classList.remove('z-index-50')
    }

    document.body.classList.remove('noscroll');
    document.body.classList.remove('onmodal');

    this.visible = false
  }

  scrollingOpen() {
    let scrollingTarget = document.querySelector('[data-trigger]').getBoundingClientRect().top

    if ( window.scrollY >= scrollingTarget ) {
      this.openModal()
    }
  }

  get sessionKey() {
    return this.sessionStorageKeyValue
  }

  get showOnce() {
    return this.showOnceValue
  }
}