import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['']

    copyToClipboard(event) {
        let ToCopy = event.currentTarget.getAttribute('data-copy');

        let temporaryElement = document.createElement("input");
        temporaryElement.value = ToCopy;

        document.body.appendChild(temporaryElement);

        temporaryElement.select();
        document.execCommand("Copy");

        document.body.removeChild(temporaryElement);
    }

}