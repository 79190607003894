import {Controller} from "stimulus"

const mediaQuery = window.matchMedia('(min-width: 1024px)')

export default class extends Controller {
  static targets = ['buttonOpen', 'form', 'input', 'submit']

  open(event) {
    this.formTarget.classList.add('opened');
    this.inputTarget.focus();
  }

  close() {
    this.formTarget.classList.remove('opened')
    this.inputTarget.value = ''
  }

  clickOutside() {
    if (this.formTarget.classList.contains('opened')) {
      let elements = [ this.buttonOpenTarget, this.inputTarget, this.submitTarget ]
      if ( elements.includes(document.activeElement) ) {
        return
      }
      this.close();
    }
  }
}