import { Controller } from "stimulus"

const mediaQuery = window.matchMedia('(max-width: 1024px)')

export default class extends Controller {
  static targets = [
    "searchInput", 'searchInputText', "navbar", "menu", "closeButton", "sections", "section",
    "buttonSection", "section__back", "categories", "overlayJoin", "closeJoinButton",
    "joinModal", "joinForm", "joinSuccess", "dropdownGuides", "overlayParent",
    "menuContent" ]

  hideShowHeader() {
    if (!this.hasMenuContentTarget) {
      return;
    }
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    // Use a offset to avoid a glitch that occurs when the scroll is in a very specific
    // position, that hiding the header makes it scroll again and it enters in an
    // infinite loop
    const offset = 10
    if (scrollTop > this.lastScrollTop + offset){
      if (this.menuContentTarget.matches(':hover')) {
        return
      } else {
        document.body.classList.add('scroll-down')
      }
    } else if (scrollTop < this.lastScrollTop - offset) {
      document.body.classList.remove('scroll-down')
    }

    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  }

  connect() {
    this.lastScrollTop = 0;
  }

  createOverlay( placement ) {
    let overlay = document.createElement('div');
    overlay.className = 'overlay';
    placement.appendChild(overlay);
    document.body.classList.add('noscroll');
  }

  removeOverlay( ) {
    let overlay = document.getElementsByClassName('overlay')[0];
    overlay.remove();
    document.body.classList.remove('noscroll');
  }

  openMenu() {
    if ( !this.menuTarget.classList.contains('visible') ) {
      this.menuTarget.classList.add('visible');
      this.closeButtonTarget.classList.add('visible');

      let navbar = this.navbarTarget
      this.createOverlay( navbar );
    }
  }

  closeMenu() {
    let currentSelected = document.querySelectorAll('.selected');
    let categories = document.getElementsByClassName('menu__categories')[0];
    for(let i=0;i<currentSelected.length;i++){
      currentSelected[i].classList.remove('selected');
    }
    this.menuTarget.classList.remove('visible');

    this.closeButtonTarget.classList.remove('visible');

    this.removeOverlay();

    if ( this.navbarTarget.contains(categories) ) {
      this.sectionsTarget.classList.add('step-sections');
      this.sectionsTarget.classList.remove('step-categories');
      this.sectionsTarget.classList.remove('step-posts');
    }

  }

  selectSection(event) {
    if (mediaQuery.matches) {
      let currentCategory = event.currentTarget.closest('.menu__section');
      currentCategory.classList.add('selected');

      this.sectionsTarget.classList.remove('step-sections');
      this.sectionsTarget.classList.add('step-categories');
    }
  }

  unselectSection(event) {
    if (mediaQuery.matches) {
      let currentCategory = event.currentTarget.closest('.menu__section');
      currentCategory.classList.remove('selected');

      this.sectionsTarget.classList.add('step-sections');
      this.sectionsTarget.classList.remove('step-categories');
      event.stopPropagation();
    }
  }

  selectCategory(event) {
    if (mediaQuery.matches) {
      let currentSubCategory = event.currentTarget.closest('.menu__category');
      currentSubCategory.classList.add('selected');

      let postBlock = currentSubCategory.querySelector('.menu-posts')
      postBlock.classList.add('selected');

      this.sectionsTarget.classList.add('step-posts');
      this.sectionsTarget.classList.remove('step-categories');
      event.stopPropagation();
    }
  }

  unselectCategory(event) {
    if (mediaQuery.matches) {
      let currentCategory = event.currentTarget.closest('.menu__category');
      currentCategory.classList.remove('selected');

      let postBlock = currentCategory.querySelector('.menu-posts')
      postBlock.classList.remove('selected');

      this.sectionsTarget.classList.add('step-categories');
      this.sectionsTarget.classList.remove('step-posts');
      event.stopPropagation();
    }
  }

  mouseoverCategory(event) {
    let postBlock = event.currentTarget.querySelector('.menu-posts')
    let blockHeight = postBlock.scrollHeight;

    event.currentTarget.closest('.menu-sub').style.minHeight = blockHeight + 'px';
  }

  mouseoutCategory(event) {
    event.currentTarget.closest('.menu-sub').style.minHeight = 'auto';
  }

  openJoin() {
    let overlayTarget = this.navbarTarget

    if (this.hasOverlayParentTarget) {
      overlayTarget = this.overlayParentTarget
    }

    this.createOverlay(overlayTarget);
    this.joinModalTarget.classList.add('visible');
    this.closeJoinButtonTarget.classList.add('visible');

    if ( mediaQuery.matches ) {
      this.closeMenu()
    }
  }

  successJoin() {
    this.joinFormTarget.classList.remove('visible');
    this.joinSuccessTarget.classList.add('visible');
  }

  closeJoin() {
    this.joinModalTarget.classList.remove('visible');
    this.closeJoinButtonTarget.classList.remove('visible');
    this.removeOverlay();
  }

  openSearch() {
    this.searchInputTarget.classList.add('visible');
    if (this.hasSearchInputTextTarget) {
      this.searchInputTextTarget.focus()
    }
  }

  focusInput(event) {
    let currentInput = event.currentTarget.closest('form')
    currentInput.classList.add('active')
  }

  get autosuggestResultsShown() {
    return this.searchInputTarget.querySelectorAll("[data-autocomplete-target='results'].visible").length > 0
  }

  closeSearch(event) {
    if (this.autosuggestResultsShown) {
      return
    }

    console.log(event)
    let currentInput = event.currentTarget.closest('form')
    currentInput.classList.remove('active')
    this.searchInputTarget.classList.remove('visible');
  }

  toggleDropdown(event) {
    this.dropdownGuidesTarget.classList.toggle('visible');
    event.currentTarget.classList.toggle('pressed');
  }
}