import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "currentTitle", "currentDuration", "nextButton", "nextTitle",
                     "iframeContainer" ]

  connect() {
    this.index = -1
    this.videoUrls = JSON.parse(this.element.dataset.videos)
  }

  loadVideoByUrl(event) {
    let url = event.currentTarget.dataset.videoUrl;
    if (!url) {
      return
    }

    let index = this.videoUrls.findIndex((item) => item.url === url)
    if (index < 0) {
      return
    }

    this.loadVideoByIndex(index)
  }

  isLastVideo() {
    return this.index === this.videoUrls.length - 1
  }

  loadVideoByIndex(index) {
    this.index = index

    this.currentTitleTarget.innerText = this.videoUrls[index].title
    this.currentDurationTarget.innerHTML = `<i class="icon-cooking-time s-16 mr-10"> ${this.videoUrls[index].duration}`
    this.iframeContainerTarget.innerHTML = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${this.videoUrls[index].id}?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`

    if (this.isLastVideo()) {
      this.nextButtonTarget.classList.add('v-hidden')
      this.nextTitleTarget.innerText = ''
    }
    else {
      this.nextButtonTarget.classList.remove('v-hidden')
      this.nextTitleTarget.innerText = this.videoUrls[index + 1].title
    }
  }

  nextVideo() {
    if (this.isLastVideo()) {
      return
    }

    this.loadVideoByIndex(this.index + 1)
  }

  close() {
    this.iframeContainerTarget.innerHTML = ''
  }

}