import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['item']
  static values = {
    total: Number,
    interval: Number
  }

  connect() {
    this.intervalFn = setInterval(() => this.next(), this.interval);
  }

  disconnect() {
    clearInterval(this.intervalFn)
  }

  get interval() {
    return this.intervalValue || 10000;
  }

  get visibleItems() {
    return this.itemTargets.filter(x => x.classList.contains('visible'))
  }

  next() {
    const visibleItems = this.visibleItems

    let index = parseInt(visibleItems[0].dataset.index)
    let nextIndex = (index + 1) % this.totalValue

    // Hide the current ones
    visibleItems.forEach(x => x.classList.remove('visible'))
    // Display the next batch
    this.itemTargets.filter(x => parseInt(x.dataset.index) === nextIndex)
                    .forEach(x => x.classList.add('visible'))
  }
}