import { Controller } from "stimulus"

export default class extends Controller {
  run(event) {
    let expectedKey = this.element.dataset.hotkeyKeyValue

    if (!expectedKey || event.key !== expectedKey) {
      return;
    }

    // If the focus is on a writable element, don't do anything. The user might be typing
    // some text.
    //
    // TODO: Find a better way of handling this, and make sure it works in all scenarios
    if (document.activeElement.value !== undefined) {
      return;
    }

    event.preventDefault();
    this.element.click();
  }
}