import { Controller } from "stimulus"
import { secondsToTime } from "../helpers"


export default class extends Controller {
  static targets = ['videoElement', 'duration']

  setDuration() {
    if (!this.hasDurationTarget) {
      return;
    }

    // TODO: Change this! don't set the icon in here
    this.durationTarget.innerText = secondsToTime(this.videoElementTarget.duration)
  }

  play() {
    this.videoElementTarget.play()
  }
}