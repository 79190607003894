import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container', 'errorText', 'totalComments', 'contentInput',
                    'confirmationContainer', 'confirmationMessage',
                    'tokenInput', 'emailInput', 'emailConfirmationInput', 'form']

  connect() {
    if (this.hasEmailConfirmationInputTarget) {
      // This field isn't required for real users, we're using it as a honeypot!
      this.emailConfirmationInputTarget.removeAttribute('required')
    }

    if (this.hasTokenInputTarget) {
      this.originalTokenValue = this.tokenInputTarget.value;
    }
  }

  onFormSubmit(event) {
    // Make sure we set the correct value in the token on runtime. This should prevent
    // generic bots from fetching the HTML, filling some inputs and submitting the form
    if (this.hasTokenInputTarget) {
      let authenticityToken = this.formTarget.querySelector('input[name="authenticity_token"]').value
      this.tokenInputTarget.value = authenticityToken.split("").reverse().join("")
    }
  }

  // Action used after a Rails remote form is submitted successfully
  onCommentSuccess(event) {
    let [data, status, xhr] = event.detail;
    // Hide the form
    if (data.success) {
      if (data.html) {
        this.containerTarget.insertAdjacentHTML('beforeend', data.html)
      }
      if (data.count) {
        this.totalCommentsTarget.innerText = `${data.count} comments`
      }
      if (data.message && this.hasConfirmationContainerTarget && this.hasConfirmationMessageTarget) {
        // Display the confirmation message
        this.confirmationMessageTarget.innerText = data.message
        this.confirmationContainerTarget.classList.add('visible')
        // Hide the form
        this.formTarget.classList.add('d-none')

        // Notify other controllers about this
        this.fireEvent('comment-created')
      }
      // Reset the comment's input text
      this.contentInputTarget.value = null
    } else {
      this.showError(data.message)
    }

    if (this.hasTokenInputTarget) {
      this.tokenInputTarget.value = this.originalTokenValue
    }
  }

  fireEvent(eventName) {
    const event = new CustomEvent(eventName)
    window.dispatchEvent(event)
  }

  onCommentError(event) {
    let [data, status, xhr] = event.detail;
    this.showError('An unexpected error occurred. Please, try again later.')
  }

  showError(message) {
    this.errorTextTarget.innerText = message
    this.errorTextTarget.classList.remove('d-none')
  }
}