import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "disclosure", "pricesButton", "pricesBlock", "postsListContainer", "rangeInput","policyAmountValue" ]

  initialize() {
    this.page = 1;
  }

  showPrices() {
    this.pricesButtonTarget.classList.toggle('pressed');
    this.pricesBlockTarget.classList.toggle('visible');
  }

  showDisclosure() {
    this.disclosureTarget.classList.toggle('visible');
  }

  toggleSelect(event) {
    let currentDropdown = event.currentTarget.querySelector('.dropdown');
    currentDropdown.classList.toggle('visible');
  }

  avoidTyping(event) {
    event.preventDefault();
  }

  selectOption(event) {
    let currentSelection = event.currentTarget.innerText;
    let currentDropdown = event.currentTarget.closest('.dropdown__input').querySelector('.dropdown__selection');

    currentDropdown.value = currentSelection;
  }

  showMore(event) {
    event.preventDefault();

    let target = event.target;

    this.page += 1

    let url = target.getAttribute('href') + '?' + new URLSearchParams({ page: this.page })

    fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.html) {
          this.postsListContainerTarget.innerHTML += data.html
        }
        if (data.last) {
          target.classList.add('d-none')
        }
      })
      .catch((error) => {
        console.log(error)
      })

    return false;
  }

  showDescription(event) {
    let currentProduct = event.currentTarget.closest('.top-product__container'),
    descriptionBlock = currentProduct.querySelector('.top-product__description'),
    height = descriptionBlock.scrollHeight;

    descriptionBlock.classList.toggle('visible');

    if ( descriptionBlock.classList.contains('visible') ) {
      descriptionBlock.style.height = height + 'px'
    } else {
      descriptionBlock.style.height = 0 + 'px'
    }
    event.currentTarget.classList.toggle('pressed');
  }
}