import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['container', 'text']

  connect() {
    let container = window.getComputedStyle(this.containerTarget),
      containerWidth = this.containerTarget.clientWidth - parseInt(container.paddingLeft) * 2,
      textWidth = this.textTarget.offsetWidth;

    console.log(containerWidth)
    console.log(this.textTarget)
    console.log(textWidth)

    while ( containerWidth < textWidth) {
      let textFontSize = parseFloat(window.getComputedStyle(this.textTarget, null).getPropertyValue('font-size'));
      this.textTarget.setAttribute('style', 'font-size:' + (textFontSize - 0.5) + 'px' + '!important');
      textWidth = this.textTarget.offsetWidth;
    }
  }
}