import { Controller } from "stimulus";

let countdown

export default class extends Controller {
  static targets = ['days', 'hours', 'minutes', 'seconds'];
  static values = { number: Number, format: String }

  connect() {
    this.countDownClock(this.numberValue, this.formatValue)
  }

  countDownClock(number = 100, format = 'seconds') {
    this.convertFormat(format, number)
  }

  convertFormat(format, number) {
    switch (format) {
      case 'seconds':
        return this.timer(number);
      case 'minutes':
        return this.timer(number * 60);
      case 'hours':
        return this.timer(number * 60 * 60);
      case 'days':
        return this.timer(number * 60 * 60 * 24);}
  }

  timer(seconds) {
    const now = Date.now();
    const then = now + seconds * 1000;

    countdown = setInterval(() => {
      const secondsLeft = Math.round((then - Date.now()) / 1000);

      if (secondsLeft <= 0) {
        clearInterval(countdown);
        return;
      };

      this.displayTimeLeft(secondsLeft);

    }, 1000);
  }

  displayTimeLeft(seconds) {
    if (this.hasDaysTarget) (this.daysTarget.textContent = Math.floor(seconds / 86400))
    if (this.hasHoursTarget) (this.hoursTarget.textContent = Math.floor(seconds % 86400 / 3600))
    if (this.hasMinutesTarget) (this.minutesTarget.textContent = Math.floor(seconds % 86400 % 3600 / 60))
    if (this.hasSecondsTarget) (this.secondsTarget.textContent = seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60)
  }
}