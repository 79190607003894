import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'rangeInput', 'amountLabel' ]

  connect() {
    this.rangeInputTarget.setCustomValidity("Please, select a policy amount");
  }

  selectPolicyAmount(event) {
    let currentValue = event.currentTarget.value;
    let maxValue = event.currentTarget.getAttribute('max');
    let percentFilled = currentValue * 100 / maxValue;

    // Change bar's fill %
    this.rangeInputTarget.style.setProperty('--val', `${percentFilled}%`)

    if (currentValue < 40) {
      this.setLabel(`$${currentValue * 25}K`)
    }
    else if (currentValue === 40) {
      this.setLabel('1M')
    }
    else {
      this.setLabel(`+$1M`)
    }

    this.rangeInputTarget.setCustomValidity("");
  }

  setLabel(value) {
    this.amountLabelTarget.innerText = value;
  }

}