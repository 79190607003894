import { Controller } from "stimulus"

const mediaQuery = window.matchMedia('(max-width: 767px)')

export default class extends Controller {
  static targets = [ 'carouselBlock' ]

  connect() {
    setTimeout(this.carouselBuilding.bind(this), 300)
  }

  carouselBuilding() {
    let itemToShow = this.carouselBlockTarget.getAttribute('data-desktop'),
      carouselWrapper = this.carouselBlockTarget.closest('.carousel__wrapper'),
      carouselItems = this.carouselBlockTarget.querySelectorAll('.carousel__item'),
      itemsList = this.carouselBlockTarget.querySelector('.carousel__list'),
      lastItem = carouselItems[carouselItems.length - 1],
      carouselDot = carouselWrapper.querySelector('.dot');

    if (mediaQuery.matches) {
      itemToShow = this.carouselBlockTarget.getAttribute('data-mobile')
    }

    if ( carouselItems.length > itemToShow && this.element.dataset.floatControls !== '1') {
      carouselWrapper.querySelectorAll('.carousel__control').forEach( function(item) {
        item.classList.add('d-lg-block')
      })
    }

    for ( let i = 0; i < carouselItems.length - 1; i++ ) {
      let dot = carouselDot.cloneNode(true)
      carouselDot.closest('.carousel__dots').appendChild(dot)
    }

    let carouselWidth = this.carouselBlockTarget.clientWidth,
        itemCloned = lastItem.cloneNode(true);

    itemsList.prepend(itemCloned)

    carouselItems = this.carouselBlockTarget.querySelectorAll('.carousel__item');

    carouselItems.forEach( function(item) {
      item.style.width = carouselWidth / itemToShow + 'px'
      item.style.minWidth = carouselWidth / itemToShow + 'px'
    })

    itemsList.style.width = itemsList.scrollWidth + 'px'

    itemsList.style.transform = 'translateX(-'+ carouselItems[0].scrollWidth +'px)'

    this.element.classList.remove('v-hidden')
  }

  touchStart(event) {
    this.clientX = event.touches[0].pageX;
  }

  touchEnd() {
    let deltaX = event.changedTouches[0].pageX - this.clientX;
    if ( deltaX > 0 ) {
      this.prevItem()
    } else if ( deltaX < 0 ) {
      this.nextItem()
    }
  }


  nextItem() {
    let carouselItems = this.carouselBlockTarget.querySelectorAll('.carousel__item'),
      itemsList = this.carouselBlockTarget.querySelector('.carousel__list'),
      firstItem = carouselItems[0],
      itemToClone = carouselItems[1];

    firstItem.classList.add('removing')
    setTimeout( function (){
      firstItem.remove()
    }, 200 )

    let itemCloned = itemToClone.cloneNode(true)
    itemsList.append(itemCloned)
  }

  prevItem() {
    let carouselItems = this.carouselBlockTarget.querySelectorAll('.carousel__item'),
      itemsList = this.carouselBlockTarget.querySelector('.carousel__list'),
      lastItem = carouselItems[carouselItems.length - 1],
      itemToClone = carouselItems[carouselItems.length - 2];

    lastItem.remove()

    let itemCloned = itemToClone.cloneNode(true)
    itemCloned.classList.add('removing')
    itemsList.prepend(itemCloned)

    setTimeout( function (){
      itemCloned.classList.remove('removing')
    }, 200 )
  }
}