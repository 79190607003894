import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container', 'layout', 'inputField']
  static values = {
    max: Number,
    fireEvent: Boolean,
    persistClickedValue: Boolean
  }

  initialize() {
    this.currentWidth = 0
    this.currentValue = 0
  }

  setWidthValue(e) {
    this.setWidthValueFromEvent(e, false)
  }

  applyValue(e) {
    this.setWidthValueFromEvent(e, true)
  }

  // Sets the rating (stars) value from one of our custom events, fired
  // by a different rating_controller instance
  setExternalRatingValue(e) {
    this.currentValue = e.detail.rating
    this.setWidthValueAux(true)
  }

  // Discards the current displayed value (after user mouse overs the stars), unless
  // the user clicked on it.
  discardHighlightedValue() {
    this.setWidth(this.currentWidth)
  }

  get step() {
    return Math.round(100 / this.maxValue);
  }

  setWidthValueFromEvent(event, save) {
    const container = this.containerTarget.getBoundingClientRect()
    const percentage = (event.pageX - container.left) * 100 / container.width
    // The real value we're gonna be using in the backend (eg. 3, 4, 5)
    this.currentValue = Math.ceil(percentage / this.step)

    this.setWidthValueAux(save)
  }

  setWidthValueAux(save) {
    // Te value converted to a width (100%-based)
    const finalWidth =  this.currentValue * this.step

    if (save) {
      if (this.persistClickedValueValue) {
        this.currentWidth = finalWidth
        this.inputFieldTarget.value = this.currentValue
      }

      // Notify other controllers about this
      if (this.fireEventValue) {
        this.fireEvent('rating-selected')
      }
    }

    this.setWidth(finalWidth)
  }

  setWidth(value) {
    this.layoutTarget.style.width = value + '%'
  }

  fireEvent(eventName) {
    const event = new CustomEvent(eventName, { detail: { rating: this.currentValue } })
    window.dispatchEvent(event)
  }
}