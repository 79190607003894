import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    class: String,
    element: String,
    elementClass: String
  }

  connect() {
    this.elementToWatch = document.getElementById(this.elementValue);
    if (this.elementToWatch) {
      this.elementToWatchObserver = new MutationObserver(this.checkClass.bind(this));
      this.elementToWatchObserver.observe(this.elementToWatch, {
        attributes: true,
        attributeFilter: ["class"]
      });
    }
  }

  disconnect() {
    if (this.elementToWatchObserver) {
      this.elementToWatchObserver.disconnect();
    }
  }

  checkClass() {
    if (this.elementToWatch.classList.contains(this.elementClassValue)) {
      this.element.classList.add(this.classValue);
    } else {
      this.element.classList.remove(this.classValue);
    }
  }
}
