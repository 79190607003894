import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'carouselBlock', 'carouselList', 'leftButton', 'rightButton' ]
  static values = { preventTouch: Boolean }

  connect() {
    this.resizeObserver = new ResizeObserver(this.hideShowButtons.bind(this))
    this.resizeObserver.observe(this.carouselListTarget)
  }

  disconnect() {
    this.resizeObserver.unobserve(this.carouselListTarget)
  }

  touchStart(event) {
    if (!this.preventTouchValue) {
      return;
    }
    // Make sure it doesn't start scrolling automatically!
    event.preventDefault()
    this.clientX = event.touches[0].pageX;
  }

  touchEnd(event) {
    if (!this.preventTouchValue) {
      return;
    }
    let deltaX = event.changedTouches[0].pageX - this.clientX;
    if ( deltaX > 0 ) {
      this.scrollLeft()
    } else if ( deltaX < 0 ) {
      this.scrollRight()
    }
  }

  scrollRight() {
    if (this.currentScrollValue >= this.maxScrollValue) {
      return;
    }

    let carouselListRight = Math.round(this.carouselListTarget.getBoundingClientRect().right)

    let _lastVisibleItem = this.lastVisibleItem
    let _lastVisibleItemRight = Math.round(_lastVisibleItem.getBoundingClientRect().right)

    // How many items are we gonna scroll at once.
    let step = this.visibleItemsCount

    let scrollTo = this.currentScrollValue + (_lastVisibleItemRight - carouselListRight) + (step - 1) * Math.round(_lastVisibleItem.offsetWidth)

    this.carouselListTarget.scrollTo({ top: 0, left: scrollTo, behavior: 'smooth'})
  }

  scrollLeft() {
    if (this.currentScrollValue <= 1) {
      return;
    }

    let carouselListLeft = Math.round(this.carouselListTarget.getBoundingClientRect().left)

    let _firstVisibleItem = this.firstVisibleItem

    let _firstVisibleItemLeft = Math.round(_firstVisibleItem.getBoundingClientRect().left)

    // How many items are we gonna scroll at once.
    let step = this.visibleItemsCount

    let scrollTo = this.currentScrollValue - (carouselListLeft - _firstVisibleItemLeft) - (step - 1) * Math.round(_firstVisibleItem.offsetWidth)

    this.carouselListTarget.scrollTo({ top: 0, left: scrollTo, behavior: 'smooth'})
  }

  hideShowButtons() {
    if (this.currentScrollValue <= 1) {
      this.leftButtonTarget.classList.add('v-hidden')
    }
    else {
      this.leftButtonTarget.classList.remove('v-hidden')
    }

    if (Math.round(this.currentScrollValue) >= Math.round(this.maxScrollValue) - 1) {
      this.rightButtonTarget.classList.add('v-hidden')
    }
    else {
      this.rightButtonTarget.classList.remove('v-hidden')
    }
  }

  scrolled() {
    if (!this.hasControlButtons) {
      return;
    }

    this.hideShowButtons()
  }

  get currentScrollValue() {
    return this.carouselListTarget.scrollLeft
  }

  get maxScrollValue() {
    return this.carouselListTarget.scrollWidth - this.carouselListTarget.clientWidth;
  }

  get hasControlButtons() {
    return this.hasLeftButtonTarget && this.hasRightButtonTarget
  }

  get lastVisibleItem() {
    let carouselListRight = this.carouselListTarget.getBoundingClientRect().right
    return Array.from(this.carouselListTarget.querySelectorAll('.carousel__item')).find(item => {
      return item.getBoundingClientRect().right > carouselListRight + 1
    })
  }

  get firstVisibleItem() {
    let carouselListLeft = this.carouselListTarget.getBoundingClientRect().left

    return Array.from(this.carouselListTarget.querySelectorAll('.carousel__item')).reverse().find(item => {
      return item.getBoundingClientRect().left < carouselListLeft - 1
    })
  }

  get firstCarouselItem() {
    return this.carouselListTarget.querySelector('.carousel__item')
  }

  get visibleItemsCount() {
    return Math.floor(this.carouselListTarget.getBoundingClientRect().width / this.firstCarouselItem.getBoundingClientRect().width)
  }
}