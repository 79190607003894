import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  // Opens the URL specified in the link/form/element attribute in a new tab, and
  // makes sure the form is submitted in the current one
  openInNewTab() {
    let url = this.element.dataset.url

    if (!url) {
      return;
    }

    // Make sure the form doesn't open in a new tab
    this.element.removeAttribute('target')

    window.open(url, '_blank');
  }
}