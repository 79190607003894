import { Controller } from "stimulus"
import ModalController from './modal_controller'

const mediaQuery = window.matchMedia('(max-width: 767px)')

export default class extends ModalController {
  static targets = ModalController.targets.concat(['imagesList', 'thumbnailsList', 'thumbnailsBlock', 'prevButton', 'nextButton', 'controlPrev', 'controlNext'])

  connect() {
    super.connect()

    this.currentIndex = 0
  }

  openModal(event) {
    super.openModal(event)

    // If the list of thumbnails have to be scrollable (using vertical arrows)
    if (this.thumbnailsListTarget.scrollHeight > this.thumbnailsBlockTarget.scrollHeight) {
      this.thumbnailsBlockTarget.classList.add('scrollable')
    }
  }

  showImage(event) {
    let activeThumbnailIndex = parseInt(event.currentTarget.dataset.imageIndex)
    this.showImageAux(activeThumbnailIndex)
  }

  get images() {
    return this.imagesListTarget.querySelectorAll('.gallery__picture')
  }

  showImageAux(index) {
    let activeThumbnail = this.thumbnailsListTarget.querySelector('.gallery__thumb.active')

    this.currentIndex = index

    // Mark the correct thumbnail as active
    activeThumbnail.classList.remove('active')
    let targetThumbnail = this.thumbnailsListTarget.querySelector(`.gallery__thumb[data-image-index="${index}"]`)
    targetThumbnail.classList.add('active')

    this.images.forEach((item) => {
      // Hide all the images, except the one that matches the selected index
      item.classList.remove('active')
      if (item.dataset.imageIndex === index.toString()) {
        item.classList.add('active')
      }
    })

    this.showHideButtons()
  }

  showHideButtons() {
    this.controlPrevTarget.disabled = this.currentIndex === 0
    this.controlNextTarget.disabled = this.currentIndex === this.images.length - 1
  }

  scrollUpDown(multiplier) {
    let item = this.thumbnailsListTarget.querySelector('.gallery__thumb')
    let itemHeight = item.scrollHeight + parseInt(window.getComputedStyle(item).marginTop)

    let top = (itemHeight * multiplier * 4)

    this.thumbnailsListTarget.scrollTo({
      top: this.thumbnailsListTarget.scrollTop + top,
      behavior: 'smooth'
    })

    this.prevButtonTarget.disabled = this.thumbnailsListTarget.scrollTop + top <= 0
    this.nextButtonTarget.disabled = this.thumbnailsListTarget.offsetHeight + (this.thumbnailsListTarget.scrollTop + top) >= this.thumbnailsListTarget.scrollHeight
  }

  scrollUp() {
    this.scrollUpDown(-1)
  }

  scrollDown() {
    this.scrollUpDown(1)
  }

  touchStart(event) {
    this.clientX = event.touches[0].pageX;
    this.clientY = event.touches[0].pageY;
  }

  touchEnd() {
    let deltaX = event.changedTouches[0].pageX - this.clientX;
    let deltaY = event.changedTouches[0].pageY - this.clientY;

    if (Math.abs(deltaY) > Math.abs(deltaX)) {
      super.closeModal()
      return;
    }


    if ( deltaX > 0 ) {
      this.prevImage()
    } else if ( deltaX < 0 ) {
      this.nextImage()
    }
  }

  prevImage() {
    if (this.currentIndex === 0) {
      return;
    }

    this.showImageAux(this.currentIndex - 1)
  }

  nextImage() {
    if (this.currentIndex === this.images.length - 1) {
      return;
    }

    this.showImageAux(this.currentIndex + 1)
  }
}