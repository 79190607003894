import { Controller } from "stimulus"

const mobileTabletView = window.matchMedia('(max-width: 1023px)')

export default class extends Controller {
  static targets = ['elementToClone', 'container']
  static values = { overlay: Boolean, mediaQuery: String }

  connect() {
    this.visible = false
  }

  closeOnClickOutside({ target }) {
    if (!this.visible) {
      return
    }

    const container = this.hasContainerTarget ? this.containerTarget : document.body;
    if (!container.querySelector('.overlay')) {
      return
    }

    if (this.element.contains(target) && !target.classList.contains('overlay')) {
      return;
    }

    this.removeElement();
  }

  cloneElement(event) {
    if (this.hasMediaQueryValue && this.mediaQueryValue === 'no-desktop') {
      if (mobileTabletView.matches) {
        this.showElement();
      }
    } else {
      this.showElement();
    }

  }

  showElement() {
    const container = this.hasContainerTarget ? this.containerTarget : document.body;

    if (this.hasOverlayValue) {
      let overlay = document.createElement('div');
      overlay.className = 'overlay';
      container.appendChild(overlay);
    }

    const cloned = this.elementToCloneTarget.cloneNode(true);
    cloned.classList.add('cloned')
    container.appendChild(cloned)
    this.visible = true
  }

  removeElement() {
    const container = this.hasContainerTarget ? this.containerTarget : document.body;

    const overlay = container.querySelector('.overlay');
    if (overlay) {
      overlay.remove();
    }

    const cloned = container.querySelector('.cloned');
    if (cloned) {
      cloned.remove();
    }
    this.visible = false
  }
}