import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['columnContainer1', 'columnContainer2', 'columnContainer3']

  appendResult(object) {
    // We're expecting an array of 3 strings (HTML).
    this.columnContainer1Target.insertAdjacentHTML('beforeend', object[0])
    this.columnContainer2Target.insertAdjacentHTML('beforeend', object[1])
    this.columnContainer3Target.insertAdjacentHTML('beforeend', object[2])
  }
}